import { useLocation } from "@reach/router";

export function MetaTitle() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Eco Spindles Sri Lanka | Polyester Yarn and Filament Manufacturer | Plastic Recycler";
  } else if (pathname === "about") {
    return "Eco Spindles | Yarn Manufacturer | About Us | Plastic Recycler";
  } else if (pathname === "initiatives") {
    return "CSR Initiatives | Plastic Recycler | Eco Spindles Sri Lanka";
  } else if (pathname === "products") {
    return "Yarns and Filaments products | Plastic Recycler | Eco Spindles";
  } else if (pathname === "product-yarn") {
    return "Yarn Manufacturer in Asia | Polyester Yarn | Eco Spindles";
  } else if (pathname === "products-filament") {
    return "Monofilament manufacturer in Asia | PET recyclers | Eco Spindles";
  } else if (pathname === "pet-recycling") {
    return "Plastic waste collectors | Plastic Recycler | Eco Spindles";
  } else if (pathname === "contact-us") {
    return "Contact us | Polyester Yarn and Filament Manufacturer | Eco Spindles Sri Lanka";
  } else if (pathname === "ocean-waste-management") {
    return "CSR Initiatives | Coastal plastic waste management | Eco Spindles";
  } else if (pathname === "pilgrimage-waste-management") {
    return "CSR Initiatives | Pilgrimage waste management | Yarn and Filament Manufacturer | Eco Spindles";
  } else if (pathname === "download-waste-2-value-app") {
    return "DOWNLOAD OUR WASTE 2 VALUE APP - Eco Spindles";
  }
}

export function MetaDescription() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Eco Spindles is a leading manufacturer of polyester yarn and filaments in Sri Lanka utilizing recycled plastics as raw materials, suppling products to customers worldwide.";
  } else if (pathname === "about") {
    return "Eco-Spindles, Sri Lanka is a forward-looking company focused on sustainable development. Innovative technology is used to manufacture products from recycled plastics, reducing the carbon footprint generated.";
  } else if (pathname === "initiatives") {
    return "We strive to solve one of the greatest environmental problems by including every citizen into the recycling ecosystem.";
  } else if (pathname === "products") {
    return "Explore our range of polyester yarns and filaments crafted from recycled plastic waste sourced through initiatives across Sri Lanka.";
  } else if (pathname === "product-yarn") {
    return "As the leading yarn manufacturer in Asia, discover Eco Spindles wide range of yarns produced the state-of-the-art plant, transforming plastic waste into textiles.";
  } else if (pathname === "products-filament") {
    return "Eco Spindles, as a leading monofilament manufacturer in Asia, provides a wide array of premium polypropylene, PET, nylon, and polyester filaments for cleaning tools. Discover our diverse range of monofilaments today.";
  } else if (pathname === "pet-recycling") {
    return "Eco Spindles is a leading plastic waste collector in Sri Lanka, offering the best price and collection capacity.";
  } else if (pathname === "contact-us") {
    return "Contact Eco Spindles regarding your concerns and questions. Simply Email info@ecospindles.com or call +94 11 2307168 / +94 11 2307170.";
  } else if (pathname === "ocean-waste-management") {
    return "Our coastal waste management initiative protects marine life and beaches by collecting 20 tons of plastic waste each month.";
  } else if (pathname === "pilgrimage-waste-management") {
    return "Our pilgrimage waste management initiative collects plastic waste from all religious event sites in Sri Lanka through established and maintained collection bins.";
  } else if (pathname === "download-waste-2-value-app") {
    return "Download Our WASTE 2 VALUE APP Android App DOWNLOAD IOS App DOWNLOAD";
  }
}

export function MetaKeywords() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Eco Spindles Sri Lanka | Polyester Yarn and Filament Manufacturer | Plastic Recycler";
  } else if (pathname === "about") {
    return "Eco Spindles | Yarn Manufacturer | About Us | Plastic Recycler";
  } else if (pathname === "initiatives") {
    return "CSR Initiatives | Plastic Recycler | Eco Spindles Sri Lanka";
  } else if (pathname === "products") {
    return "Yarns and Filaments products | Plastic Recycler | Eco Spindles";
  } else if (pathname === "product-yarn") {
    return "Polyester Yarn | Yarn Manufacturer | Recycled Yarn | Eco Spindles";
  } else if (pathname === "products-filament") {
    return "Monofilament manufacturers | PET recyclers | Eco Spindles";
  } else if (pathname === "pet-recycling") {
    return "Plastic waste collectors | Plastic Recycler | Eco Spindles";
  } else if (pathname === "contact-us") {
    return "Contact us | Polyester Yarn and Filament Manufacturer | Eco Spindles Sri Lanka";
  } else if (pathname === "ocean-waste-management") {
    return "CSR Initiatives | Coastal plastic waste management | Eco Spindles";
  } else if (pathname === "pilgrimage-waste-management") {
    return "CSR Initiatives | Pilgrimage waste management | Yarn and Filament Manufacturer | Eco Spindles";
  } else if (pathname === "download-waste-2-value-app") {
    return "DOWNLOAD OUR WASTE 2 VALUE APP - Eco Spindles";
  }
}

export function MetaImage() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/home/long-logo-1.png";
  } else {
    return "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/home/long-logo-1.png";
  }
}

export function MetaOGTitle() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Eco Spindles Sri Lanka | Polyester Yarn and Filament Manufacturer | Plastic Recycler";
  } else if (pathname === "about") {
    return "Eco Spindles | Yarn Manufacturer | About Us | Plastic Recycler";
  } else if (pathname === "initiatives") {
    return "CSR Initiatives | Plastic Recycler | Eco Spindles Sri Lanka";
  } else if (pathname === "products") {
    return "Yarns and Filaments products | Plastic Recycler | Eco Spindles";
  } else if (pathname === "product-yarn") {
    return "Yarn Manufacturer in Asia | Polyester Yarn | Eco Spindles";
  } else if (pathname === "products-filament") {
    return "Monofilament manufacturer in Asia | PET recyclers | Eco Spindles";
  } else if (pathname === "pet-recycling") {
    return "Plastic waste collectors | Plastic Recycler | Eco Spindles";
  } else if (pathname === "contact-us") {
    return "Contact us | Polyester Yarn and Filament Manufacturer | Eco Spindles Sri Lanka";
  } else if (pathname === "ocean-waste-management") {
    return "CSR Initiatives | Coastal plastic waste management | Eco Spindles";
  } else if (pathname === "pilgrimage-waste-management") {
    return "CSR Initiatives | Pilgrimage waste management | Yarn and Filament Manufacturer | Eco Spindles";
  } else if (pathname === "download-waste-2-value-app") {
    return "DOWNLOAD OUR WASTE 2 VALUE APP - Eco Spindles";
  }
}

export function MetaOGDescription() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Eco Spindles is a leading manufacturer of polyester yarn and filaments in Sri Lanka utilizing recycled plastics as raw materials, suppling products to customers worldwide.";
  } else if (pathname === "about") {
    return "Eco-Spindles, Sri Lanka is a forward-looking company focused on sustainable development. Innovative technology is used to manufacture products from recycled plastics, reducing the carbon footprint generated.";
  } else if (pathname === "initiatives") {
    return "We strive to solve one of the greatest environmental problems by including every citizen into the recycling ecosystem.";
  } else if (pathname === "products") {
    return "Explore our range of polyester yarns and filaments crafted from recycled plastic waste sourced through initiatives across Sri Lanka.";
  } else if (pathname === "product-yarn") {
    return "As the leading yarn manufacturer in Asia, discover Eco Spindles wide range of yarns produced the state-of-the-art plant, transforming plastic waste into textiles.";
  } else if (pathname === "products-filament") {
    return "Eco Spindles, as a leading monofilament manufacturer in Asia, provides a wide array of premium polypropylene, PET, nylon, and polyester filaments for cleaning tools. Discover our diverse range of monofilaments today.";
  } else if (pathname === "pet-recycling") {
    return "Eco Spindles is a leading plastic waste collector in Sri Lanka, offering the best price and collection capacity.";
  } else if (pathname === "contact-us") {
    return "Contact Eco Spindles regarding your concerns and questions. Simply Email info@ecospindles.com or call +94 11 2307168 / +94 11 2307170.";
  } else if (pathname === "ocean-waste-management") {
    return "Our coastal waste management initiative protects marine life and beaches by collecting 20 tons of plastic waste each month.";
  } else if (pathname === "pilgrimage-waste-management") {
    return "Our pilgrimage waste management initiative collects plastic waste from all religious event sites in Sri Lanka through established and maintained collection bins.";
  } else if (pathname === "download-waste-2-value-app") {
    return "Download Our WASTE 2 VALUE APP Android App DOWNLOAD IOS App DOWNLOAD";
  }
}

export function MetaURL() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "https://www.ecospindles.com/";
  } else if (pathname === "about") {
    return "https://www.ecospindles.com/about/";
  } else if (pathname === "initiatives") {
    return "https://www.ecospindles.com/initiatives/";
  } else if (pathname === "products") {
    return "https://www.ecospindles.com/products/";
  } else if (pathname === "product-yarn") {
    return "https://www.ecospindles.com/product-yarn/";
  } else if (pathname === "products-filament") {
    return "https://www.ecospindles.com/products-filament/";
  } else if (pathname === "pet-recycling") {
    return "https://www.ecospindles.com/pet-recycling/";
  } else if (pathname === "contact-us") {
    return "https://www.ecospindles.com/contact-us/";
  } else if (pathname === "ocean-waste-management") {
    return "https://www.ecospindles.com/ocean-waste-management/";
  } else if (pathname === "pilgrimage-waste-management") {
    return "https://www.ecospindles.com/pilgrimage-waste-management/";
  } else if (pathname === "download-waste-2-value-app") {
    return "https://www.ecospindles.com/download-waste-2-value-app/";
  }
}
