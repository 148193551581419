import React from "react";
import NavBar from "../components/navigation-header";
import "../styling/templates.css";
//import HomeVideo from "../images/about-video.mp4";
import Footer from "../components/footer";

export default function aboutUs() {

  const handleScrollToForm = () => {
    const formElement = document.getElementById("form-section");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="form-section">
      <NavBar />

      <div style={{ position: "relative" }}>
        <video autoPlay loop muted className="home-video">
          <source
            src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/about-video.webm"
            type="video/webm"
          />
        </video>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "80vh",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            zIndex: 0,
          }}
        ></div>
        <div className="video-text-box">
          <div className="video-background-text">
            <h1 className="video-topic">
              WE CONVERT RENEWABLE
              <br />
              RESOURCES INTO
              <br />
              INNOVATIVE PRODUCTS FOR
              <br />A CLEANER, GREENER
              <br />
              PLANET.
            </h1>
          </div>
        </div>
      </div>

      {/* session - 1 */}
      <div>
        <center>
          <div className="page-content">
            <div className="about-s1">
              <div className="about-s1-two-column-container">
                <div className="about-s1-first-column">
                  {/* Content for the first column */}
                  <h1 className="topic">
                    TAKING SRI LANKA
                    <br />
                    TOWARDS A SUSTAINABLE
                    <br />
                    FUTURE EVERYDAY   
                  </h1>
                  <p className="para">
                    Our mission is simple: Reduce the carbon footprint we make
                    on our world. By using plastic waste to create high-grade
                    yarn and filaments, we strive to solve one of the greatest
                    environmental problems plaguing our planet. The products are
                    proof that we can live more sustainably with the help of
                    technology.
                  </p>
                  <p className="para">
                    Eco Spindles further prepares for the future by hosting
                    several initiatives across Sri Lanka. The initiatives foster
                    eco-awareness in the populace while creating a recycling
                    infrastructure that will last for generations.
                  </p>
                </div>
                <div className="about-s1-second-column">
                  {/* Content for the second column */}
                  <div className="about-image-box">
                    <a href="https://www.bpplholdings.com/" aria-label="Home">
                      <img
                        className="about-image"
                        alt=""
                        src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/BPL-logo-e1611927856257-qgclgkwvgzlyckb4194jz0u5blk8v1keaeuefg2qrk.png"
                      />
                    </a>
                  </div>

                  <div className="about-image-box">
                    <img
                      className="about-image-2"
                      alt=""
                      src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/Recycling-Hero-logo-2021.jpg"
                    />
                  </div>

                  <div className="about-image-box">
                    <img
                      className="about-image-2"
                      alt=""
                      src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/forbes-asia.jpg"
                    />
                  </div>

                  <div className="about-image-box">
                    <img
                      className="about-image-2"
                      alt=""
                      src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/IFS-Sustainability-award.jpg"
                    />
                  </div>

                  <div className="about-image-box">
                    <img
                      className="about-image-2"
                      alt=""
                      src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/Awards-03.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      {/* session - 2 */}
      <div className="about-s2">
        <center>
          <h2 className="about-w-topic">OUR BUSINESS SEGMENTS</h2>
          <div className="icon-button-a">
            <div className="icon-button-a-column" id="column1">
              <a href="/products-filament/" aria-label="button">
                <div className="button-image-1" />
              </a>
              <a
                href="/products-filament/"
                aria-label="button"
                className="link"
              >
                <h2 className="about-w-button"> MONOFILAMENTS</h2>
              </a>
            </div>
            <div className="icon-button-a-column" id="column2">
              <a href="/product-yarn/" aria-label="button">
                <div className="button-image-2" />
              </a>
              <a href="/product-yarn/" aria-label="button" className="link">
                <h2 className="about-w-button">YARN</h2>
              </a>
            </div>
            <div className="icon-button-a-column" id="column2">
              <a href="/pet-recycling/" aria-label="button">
                <div className="button-image-3" />
              </a>
              <a href="/pet-recycling/" aria-label="button" className="link">
                <h2 className="about-w-button">PET RECYCLING</h2>
              </a>
            </div>
          </div>
        </center>
      </div>

      {/* session - 3 */}
      <div>
        <div className="background-scroll-container">
          <div className="about-background-image-1"></div>
          <div className="about-content-sus-1">
            <center>
              <div className="page-content-about">
                <div className="white-box">
                  <h2 className="fact-topic">FACTORIES</h2>
                  <h2 className="w-topic">
                    WE PAVE THE WAY TO THE FUTURE BY INVESTING IN NEXT
                    GENERATION RECYCLING TECHNOLOGY.
                  </h2>
                  <p className="w-para-1">
                    Based in Sri Lanka, Eco Spindles has three highly
                    sophisticated, state-of-the-art factories offering
                    substantial and extensive inventory and rapid production
                    roll-out.
                  </p>
                  <p className="w-para-2">
                    To date, we own one of only two plants in the world with the
                    capability to create yarn directly from flakes.
                  </p>
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>

      {/* session - 4 */}
      <div className="about-s4">
        <div className="three-container">
          <div className="three-column">
            <img
              src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/MONOFILAMENT.jpg"
              alt=""
              style={{
                marginTop: "50px",
                width: "100%",
                height: "auto",
                marginRight: "10px",
              }}
            />
            <div className="s4-a">
              <h2 className="s4-a-t">
                SYNTHETIC MONOFILAMENT FACTORY (MAUGAMA)
              </h2>
              <p className="w-para-1">
                The facility in Maugama uses these flakes and other plastic
                materials to produce Polypropylene, Nylon and PET based
                monofilaments for cleaning tools and paint brushes.
              </p>
            </div>
          </div>
          <div className="three-column">
            <img
              src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/yarn-factory.jpg"
              alt=""
              style={{
                marginTop: "50px",
                width: "100%",
                height: "auto",
                marginRight: "10px",
              }}
            />
            <div className="s4-a">
              <h2 className="s4-a-t">
                YARN FACTORY
                <br />
                (HORANA BOI ZONE)
              </h2>
              <p className="w-para-1">
                The most technologically advanced facility is located in the
                Horana BOI Zone where polyester yarn for fabrics are produced.
                Once again recycled PET flakes are used as input.
              </p>
            </div>
          </div>
          <div className="three-column">
            <img
              src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/about/recycling.jpg"
              alt=""
              style={{
                marginTop: "50px",
                width: "100%",
                height: "auto",
                marginRight: "10px",
              }}
            />
            <div className="s4-a">
              <h2 className="s4-a-t">
                RECYCLING PLANT
                <br />
                (HORANA BOI ZONE)
              </h2>
              <p className="w-para-1">
                Our recycling plant is Sri Lanka’s biggest recycling facility
                and represents a large part of the nation’s sustainability
                drive.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* session - 5 */}
      <div>
        <div className="background-scroll-container">
          <div className="about-background-image-2"></div>
          <div className="content-sus">
            <center>
              <div className="careers">
                <p className="careers-topic">FIND SUCCESS AT ECO SPINDLES</p>
                <p className="careers-para">
                  JOIN US TO BE A PART OF OUR GROWTH STORY.
                </p>
                <a href="#" className="careers-button" onClick={handleScrollToForm}>
                  CAREERS
                </a>
              </div>
            </center>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
